<template>
    

<div id="print_page" v-if="loaded">
   
    <div style="text-align:center">
        <div class="logo" style="display: inline-block;">סוכות נחלים</div>
        <div class="logo_subtitle">ח.פ 514066232</div>
    </div>

   <div id="print">
       
       
             
        <div id="summary_details">
            <table>
                <thead>
                    <tr>
                        <th>מק"ט</th>
                        <th>יח'</th>
                        <th>שם המוצר</th>
                        <th>מחיר</th>
                    </tr>
                </thead>
                <tbody>
                <template v-for="category in basket">
                    <template v-if="category.items.length > 0 && category.is_active" :class="(order.summary_check_parse.type == 'false' ? 'unactive' : '')">
                    <tr :key="category.id"><td colspan="4" style="font-weight:bold; padding-top:;text-align:center">{{category.name}}</td></tr>
                    <tr :key="item.id" v-for="item in category.items">
                        <template v-if="item.units > 0">
                                <td v-text="item.makat"></td>
                                <td v-text="item.units"></td>
                                <td v-html="item.name"></td>
                                <td>₪{{format_number(item.price * item.units)}}</td>
                        </template>
                    </tr>
                    <tr :key="category.id"><td colspan="4" style="font-weight:bold;">סה"כ {{category.name}}<div style="float:left;">₪{{format_number(category_total(category))}}</div></td></tr>
                    </template>
                </template>

                    <template v-if="accessories.items.length > 0" :class="(order.summary_check_parse.type == 'false' ? 'unactive' : '')">
                    <tr :key="accessories.id"><td colspan="4" style="font-weight:bold; padding-top:5px">{{accessories.title}}</td></tr>
                    <tr :key="item.id" v-for="item in accessories.items">
                        <template v-if="item.units > 0">
                                <td v-text="item.makat"></td>
                                <td v-text="item.units"></td>
                                <td v-html="item.name"></td>
                                <td>₪{{format_number(item.price * item.units)}}</td>
                        </template>
                    </tr>
                    <tr :key="accessories.id"><td colspan="4" style="font-weight:bold;">סה"כ {{accessories.title}}<div style="float:left;">₪{{format_number(category_total(accessories))}}</div></td></tr>
                    </template>

                </tbody>
                <tfoot>
                    <tr><td colspan="4" style="font-weight:bold;">סה"כ למוצרים<div style="float:left;">₪{{format_number(total_price_before_discount)}}</div></td></tr>

                    <tr v-if="order.delivery_type == '3'">
                        <td>סוג משלוח</td><td></td>
                        <td>משלוח לכניסה לבניין</td>
                        <td style="text-align: center;">₪{{price}} <span style="text-decoration:line-through" v-if="price !== price_old">₪{{price_old}}</span></td>
                    </tr>
                    <tr v-if="order.delivery_type == '1'">
                        <td>סוג משלוח</td><td></td><td>איסוף עצמי</td><td>₪0</td>
                    </tr>
                    <tr v-if="order.delivery_type == '4'">
                        <td>סוג משלוח</td><td></td><td> משלוח מצורף</td><td>₪0</td>
                    </tr>
                    <tr v-if="order.discount_amount > 0">
                        <td>הנחה</td><td></td><td v-text="order.discount_description"></td><td v-text="order.discount_amount + (order.discount_is_percent == '1' ? '%' : '₪') + '-'"></td>
                    </tr>
                    <div v-if="order.discounts && order.discounts.length > 0 && userType != 2">
                        <b> שובר</b>: 
                        <div v-for="(discount,i) in order.discounts" :key="i">
                            <span v-text="discount.discount_description"></span> - 
                            <span style="margin-left:15px;" v-text="discount.discount_amount +  '₪'"></span>
                        </div>
                    </div>
                    <tr class="total_line">
                        <td colspan="4">סה"כ<div style="float:left;" v-text="'₪' + format_number(total_price)"></div></td>
                    </tr>
                </tfoot>
                </table>
                <!-- Order Additions -->
                <div v-if="additions" id="additions_wrapper">
                    <div class="title_small">שינויים בהזמנה</div>
                    <div class="ord_title" v-html="additions.title + ' ' + additions.description"></div>
                    <label>פירוט:</label>
                    <ul>
                        <li v-for="(add,i) in additions.items" :key="i">
                            <div>הלקוח <b>{{ (add.refund == "1" ? "הוריד" : "הוסיף") }}</b>  {{ add.qty }} {{add.name}} <span dir="ltr" style="direction:ltr">   </span></div>
                        </li>
                    </ul>
                </div>
            <!-- Order Additions End -->
            </div>
        </div>
    
    <div class="user_details">
        <h3>מספר הזמנה: {{order.id}}</h3>
        <h4 v-if="order.siblings && order.siblings.length > 0" style="font-size: 24px; margin-top: -15px; margin-bottom: 15px;">הזמנות מקושרות: <router-link v-for="sib in order.siblings" :key="sib" :to="sib">{{sib}}</router-link></h4>

        <h3>פרטי לקוח</h3>
        <ul>
            <li v-for="det in client_details" :key="det.name"><label v-text="det.name"></label><span v-html="det.value"></span></li>

            <li><label>שיטת משלוח</label><span v-text="(order.delivery_type == '1' ? 'איסוף עצמי' : 'משלוח לכניסה לבניין')"></span></li>
            <li v-if="order.delivery_type == '1'"><label>אזור איסוף</label><span v-text="deliveryName"></span></li>
        </ul>
        <div v-if="order.delivery_type == '3'" style="font-size: 23px;text-align: center;font-weight: 600;color: red;">המשלוח הינו לכניסה לבניין בלבד!</div>

        <h3>דגם הסוכה</h3>
        <ul id="first_details" v-if="order.width > 0 && order.door_location != '' || order.prev_x > 0">

            <li v-for="desc in order.desc" :key="desc" v-text="desc"></li>
        </ul>


            <h3>פרטי הזמנה</h3>
        <div class="seller_info">
            <span>שם המוכר/ת: </span><span>{{seller.display_name}}</span>
            <p></p>
            <span>מספר הזמנה: </span><span style="font-weight:bold;">{{order.id}}</span>
            <p></p>
            <span>תאריך הזמנה: </span><span>{{order.date_created}}</span>

            <div style="padding-top:40px;">הנני מאשר/ת שבדקתי וראיתי שקיבלתי את כל הסחורה הנכונה של ההזמנה שלי: <span style="display:inline-block; border-bottom:1px solid #000; width:120px; padding-top: 40px;"></span></div>
        </div>
    </div>
    
    <div class="clr"></div>
       
    <div class="title2 hide-on-small-only" style="text-align: center; font-size:30px;">חג סוכות שמח</div>

   </div>

</template>

<script>
export default {
    props:["order"],
    data(){
        return{
            delivery_price: 0,
            order_data:[],
            motot:[],
            comments:[],
            seller:[],
            discounts:[],
            additions:[],
            terms: false,
            summary:[],
            client_details: [
            ],
            accessories: [],
            basket:[],
            delivery_types: [],
            coupon: '',
            price_old: 0,
            price: 0,
            loaded:false
        }
    },
    mounted(){
    
        this.$emit('updateSummary');
        this.net_api({action: 'order/get_print_delivery', data: {id: this.order.id, }}, (ret) => {
            this.price = ret.data.price;
            this.price_old = ret.data.price_old;
        });
        this.net_api({action: 'summary', data: {id: this.order.id,print:true, kits: (this.$route.query.kits == 'true') }}, (data) => {
            this.order_data = data.data.data 
            this.additions = data.data.additions;
            this.motot = data.data.motot
            this.seller = data.data.seller
            this.summary = data.data.summary
            this.delivery_types = data.data.delivery_types

            for (let i in data.data.basket)
            {
                let is_active = true;
                for (let z in data.data.basket[i].items)
                {
                    if (data.data.basket[i].items[z].is_active == '0') is_active = false;
                }
                data.data.basket[i].is_active = is_active;
            }

            this.basket = data.data.basket

            this.accessories = data.data.accessories

            this.discounts = data.data.discounts
            this.delivery_price = data.data.delivery_price

            this.client_details = [
                { name: 'שם מלא', value: '<strong style="font-size:24px;">' + this.order_data.name + '</strong>'},
                { name: 'טלפון', value: '<strong style="font-size:24px;">' + this.order_data.phone + '</strong>'},
                { name: 'דוא"ל', value: this.order_data.email},
                { name: 'רחוב ומספר', value: this.order_data.address},
                { name: 'עיר', value: '<b>' + this.order_data.address_city + '</b>'},
                { name: 'הערות נוספות', value: this.order_data.notes}
            ];

            this.coupon = (this.order_data.discount_amount > 0);

            this.loaded = true

            setTimeout(() => {
                 window.print();
            }, 300);
        });
        
  },
  computed:{
      
      total_price_before_discount(){

            let ttl = 0;
            for (let z in this.basket)
            {
                for (let i in this.basket[z].items)
                {
                    const isActive = this.basket[z].items[i].is_active == "1" || this.basket[z].items[i]?.is_active == undefined; 
                    if (isActive)
                    {
                        ttl += this.basket[z].items[i].price * this.basket[z].items[i].units; 
                        
                    }
                    else{
                        console.log(this.basket[z].items[i] );
                        
                    }
                  
                }
              
            }

            // Accessories
            for (let i in this.accessories.items)
            {
                ttl += this.accessories.items[i].price * this.accessories.items[i].units;
            }
        
          return ttl;
      },

      deliveryName(){
        
        let ret = this.delivery_types.filter((row) => { return (row.id == this.order.delivery_type_area) });
        if (!this.isNull(ret) && !this.isNull(ret[0])) return ret[0].name;
        else return '';
      },
      total_price(){

          return this.order_data.total_price;
/*
            let ttl = 0;
            for (let z in this.basket)
            {
                for (let i in this.basket[z].items)
                {
                    ttl += this.basket[z].items[i].price * this.basket[z].items[i].units;
                }
            }

            // Accessories
            for (let i in this.accessories.items)
            {
                ttl += this.accessories.items[i].price * this.accessories.items[i].units;
            }
        

            // DISCOUNT
            if (this.order.discount_amount > 0)
            {
                let discount = this.order.discount_amount;
                if (this.order.discount_is_percent) discount = ttl / 100* this.order.discount_amount

                ttl = ttl - discount;
            }

            // DELIVERY
            if (this.order.delivery_type == 3) ttl += parseFloat(this.delivery_price);


          return ttl;
          */
      }
  },
    methods:{
        category_total(category)
        {
            let ttl = 0;
            for (let i in category.items)
            {
                ttl += category.items[i].price * category.items[i].units;
            }

            return ttl;
        },
    },

}
    
</script>
<style>

    svg,i { width:13px !important; height: 13px !important; vertical-align: middle; }
</style>
<style lang="scss" scoped>
#additions_wrapper{
    .title_small{font-weight: 700;font-size: 20px;text-decoration: underline;}
    label{    font-weight: 700; font-size: 19px;}
}
h3 { font-size: 30px !important; font-weight: bold; text-decoration: underline;}
h3:not(:first-child) { padding-top: 5px; }
.user_details { float:right; width:40%; padding-right: 25px;
    h4 { padding-top: 15px;}
    ul {
        li { display: block;
            label { font-weight: bold; display: inline-block; width:100px;}
            
        }
     }
}

#print { float: right; width:60%;}



table {   border-collapse: collapse; width: 100%; font-size: 16px;
    thead { background: #eee; }
    th, td { padding: 0 12px;   border: 1px solid #ddd; }
}

.logo { font-size: 55px;
    &::after, &::before { background: #000;}
}

  .logo_subtitle { margin-top: -16px; margin-bottom: 30px;}

  .total_line { font-size: 30px; font-weight: bold;}
</style>